import { Button, Form, Input } from 'antd';
import { API_URL } from '../../../config/Api';

export default function AddZona(props) {
  const onFinish = (values) => {
    fetch(`${API_URL}/zones`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: { 'Content-Type': 'application/json' },
    });

    alert('Tudo ok');

    props.history.push('/zonas');
  };

  const onFinishFailed = (values) => {
    console.log('Finish failed:', values);
  };

  const validateMessages = {
    head_office: [{ required: true, message: 'Sede invalida' }],
    number: [{ required: true, message: 'Número invalido' }],
    email: [{ required: true, message: 'Email invalido' }],
    judge: [{ required: true, message: 'Juíz invalido' }],
    clerk: [{ required: true, message: 'Chefe cartório invalido' }],
    phone: [{ required: true, message: 'Fone invalido' }],
  };

  return (
    <>
      <h1>Nova Zona</h1>

      <Form
        name="basic"
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Sede"
          name="head_office"
          rules={validateMessages.head_office}
        >
          <Input data-cy="sede" />
        </Form.Item>

        <Form.Item
          label="Número"
          name="number"
          rules={validateMessages.number}
        >
          <Input data-cy="numero" />
        </Form.Item>

        <Form.Item
          label="Juíz"
          name="judge"
          rules={validateMessages.judge}
        >
          <Input data-cy="juiz" />
        </Form.Item>

        <Form.Item
          label="Chefe cartório"
          name="clerk"
          rules={validateMessages.clerk}
        >
          <Input data-cy="chefe_cartorio" />
        </Form.Item>

        <Form.Item
          label="Telefone"
          name="phone"
          rules={validateMessages.phone}
        >
          <Input data-cy="telefone" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={validateMessages.email}
        >
          <Input data-cy="email" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
          <Button data-cy="btn-ok" type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
