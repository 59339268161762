import { Button, notification, Switch } from 'antd';

const openNotification = () => {
  notification.open({
    message: 'Mensagem teste',
    description: 'Ola mundo.',
  });
};

export default function Alessandro() {
  return (
    <>
      <h1>Alessandro Feitoza</h1>

      <button>Clique aqui</button>

      <Button type="primary" onClick={openNotification}>
        Clique aqui
      </Button>

      <Switch />
    </>
  );
}
