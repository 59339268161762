import Alessandro from './Alessandro';
import Rodrigo from './Rodrigo';
import Ronyerisson from './Ronyerisson';
import Fabio from './Fabio';
import Kinsley from './Kinsley';
import Beremiz from './Beremiz';
import Renne from './Renne';
import Marcos from './Marcos';
import Erick from './Erick';
import LuizAires from './LuizAires';
import Jansley from './Jansley';

const object = new Object();

const pages = {
  Alessandro,
  Rodrigo,
  Ronyerisson,
  Fabio,
  Kinsley,
  Beremiz,
  Renne,
  Marcos,
  Erick,
  LuizAires,
  Jansley,
};

export default pages;
