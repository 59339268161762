import { Button, Form, Input } from 'antd';
import { API_URL } from '../../../config/Api';

export default function Add(props) {
  const onFinish = (values) => {
    fetch(`${API_URL}/vehicles`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: { 'Content-Type': 'application/json' },
    });

    alert('Tudo ok');

    props.history.push('/veiculos');
  };

  const onFinishFailed = (values) => {
    console.log('Finish failed:', values);
  };

  const validateMessages = {
    plate: [{ required: true, message: 'Placa invalida' }],
    make: [{ required: true, message: 'Marca invalida' }],
    model: [{ required: true, message: 'Modelo invalido' }],
  };

  return (
    <>
      <h1>Novo Veiculo</h1>

      <Form
        name="basic"
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Placa"
          name="plate"
          rules={validateMessages.plate}
        >
          <Input data-cy="plate" />
        </Form.Item>

        <Form.Item
          label="Marca"
          name="make"
          rules={validateMessages.make}
        >
          <Input data-cy="make" />
        </Form.Item>

        <Form.Item
          label="Modelo"
          name="model"
          rules={validateMessages.model}
        >
          <Input data-cy="model" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
          <Button type="primary" htmlType="submit" data-cy="btn-ok">
            Enviar
          </Button>
        </Form.Item>
      </Form>

    </>
  );
}
