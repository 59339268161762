import {
  Table, Space, Button, notification,
} from 'antd';
import { useEffect, useState } from 'react';
import { API_URL } from '../../../config/Api';

export default function List() {
  const [vehicles, setVehicles] = useState([]);

  const columns = [
    {
      title: 'Placa',
      dataIndex: 'plate',
      key: 'plate',
    },
    {
      title: 'Marca',
      dataIndex: 'make',
      key: 'make',
    },
    {
      title: 'Modelo',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Ação',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button>Editar</Button>
          <Button onClick={() => removeVehicle(record)}>Excluir</Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetch(`${API_URL}/vehicles`)
      .then((response) => response.json())
      .then((vehicles) => {
        setVehicles(vehicles);
      })
      .catch((error) => {
        alert('deu ruim');
      });
  }, []);

  const removeVehicle = (vehicle) => {
    fetch(`'http://localhost:8000/vehicles/${vehicle.id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }).then((result) => {
      // Atualiza o array de vehiculos
      setVehicles(vehicles.filter((vehicles) => vehicles.id !== vehicle.id));
      notification.success({ message: `Vehiculo ${vehicle.plate} excluído com sucesso! ` });
    });
  };

  return (
    <>
      <h1>Veiculos</h1>
      <Table columns={columns} dataSource={vehicles} />
    </>
  );
}
