import {
  Row, Col, Layout, Menu,
} from 'antd';
import { useState } from 'react';
import {
  BrowserRouter, Route, useLocation, useHistory,
} from 'react-router-dom';
import Navbar from './components/Navbar';
import Routes from './config/Routes';
import Login from './pages/Login';

import './app.css';

const { Header, Content, Footer } = Layout;

function Location(props) {
  const location = useLocation();
  props.setLocation(location.pathname);
  return (null);
}

function App() {
  const [location, setLocation] = useState('');
  const history = useHistory();

  return (
    <BrowserRouter>
      <Location setLocation={setLocation} />
      <Route path="/login" component={Login} />

      {location !== '/login'
        && (
        <>
          <Header>
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
              <Menu.Item key="1">nav 1</Menu.Item>
              <Menu.Item key="2">nav 2</Menu.Item>
              <Menu.Item key="3">nav 3</Menu.Item>
            </Menu>
          </Header>
          <Layout>
            <Row>
              <Col span={4}>
                <Navbar history={history} />
              </Col>
              <Col span={20} className="col">
                <Content className="content">
                  <Routes />
                </Content>
              </Col>
            </Row>
            <Footer />
          </Layout>
        </>
        )}
    </BrowserRouter>
  );
}

export default App;
