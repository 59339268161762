import { Switch, Route } from 'react-router-dom';
import Authors from '../../../pages/Authors';
import PaginaErro from '../../../pages/PaginaErro';

export default function RoutesAuthors() {
  return (
    <Switch>
      <Route exact path="/autores/alessandro" component={Authors.Alessandro} />
      <Route exact path="/autores/rodrigo" component={Authors.Rodrigo} />
      <Route exact path="/autores/ronyerisson" component={Authors.Ronyerisson} />
      <Route exact path="/autores/Kinsley" component={Authors.Kinsley} />
      <Route exact path="/autores/marcos" component={Authors.Marcos} />
      <Route exact path="/autores/beremiz" component={Authors.Beremiz} />
      <Route exact path="/autores/erick" component={Authors.Erick} />
      <Route exact path="/autores/renne" component={Authors.Renne} />
      <Route exact path="/autores/luizaires" component={Authors.LuizAires} />
      <Route exact path="/autores/jansley" component={Authors.Jansley} />
      <Route exact path="/autores/renne" component={Authors.Renne} />
      <Route exact path="/autores/alessandro" component={Authors.Alessandro} />
      <Route exact path="/autores/fabio" component={Authors.Fabio} />

      <Route path="/*" component={PaginaErro} />
    </Switch>
  );
}
