const ComponentTest = () => (
  <div>
    teste
  </div>
);

export default function Example() {
  return (
    <h1>
      Page Example

      <ComponentTest />
    </h1>
  );
}
