import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { API_URL } from '../../../config/Api';

export default function ListFaq() {
  document.title = 'FAQ';

  const [faqs, setFaqs] = useState([]);

  const columns = [
    {
      title: 'Número',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Pergunta',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Resposta',
      dataIndex: 'answer',
      key: 'answer',
    },
  ];

  useEffect(() => {
    const url = `${API_URL}/faq/`;
    fetch(url)
      .then((response) => response.json())
      .then((faqs) => {
        setFaqs(faqs);
      })
      .catch((error) => {
        alert('Não foi possível ler o banco de dados!');
      });
  }, []);

  return (
    <>
      <h1>Perguntas frequentes</h1>
      <Table columns={columns} dataSource={faqs} />
    </>
  );
}
