import { Button, notification, Switch } from 'antd';

const openNotification = () => {
  notification.open({
    message: 'Notification Title',
    description:
        'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
};
export default function Marcos() {
  return (
    <>

      <h1>Marcos</h1>
      <button>teste</button>
      <Button type="primary" onClick={openNotification}>Primary Button</Button>
      <Switch />
    </>
  );
}
