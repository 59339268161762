import { Button, Form, Input } from 'antd';
import { API_URL } from '../../../config/Api';

export default function AddUser() {
  const onFinish = (values) => {
    fetch(`${API_URL}/users`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: { 'Content-Type': 'application/json' },
    });

    alert('Tudo ok');
  };

  const onFinishFailed = (values) => {
    console.log('Finish failed:', values);
  };

  const validateMessages = {
    name: [{ required: true, message: 'Nome invalido' }],
    email: [{ required: true, message: 'Email invalido' }],
    phone: [{ required: true, message: 'Telefone invalido' }],
    password: [{ required: true, message: 'Senha invalida' }],
  };

  return (
    <>
      <h1>Novo Usuário</h1>

      <Form
        name="basic"
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Nome"
          name="name"
          rules={validateMessages.name}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={validateMessages.email}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Telefone"
          name="phone"
          rules={validateMessages.phone}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Senha"
          name="password"
          rules={validateMessages.password}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
          <Button type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
