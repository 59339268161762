import { Route, Switch } from 'react-router-dom';

import PaginaErro from '../../pages/PaginaErro';
import Example from '../../pages/Example';
import ListZones from '../../pages/Zone/List';
import ListUsers from '../../pages/User/List';
import AddUser from '../../pages/User/Add';
import RoutesAuthors from './RoutesAuthors';
import ListVehicles from '../../pages/Vehicle/List';
import RecoveryPassword from '../../pages/RecoveryPassword';
import AddVehicle from '../../pages/Vehicle/Add';
import AddZona from '../../pages/Zone/Add';
import ListFaq from '../../pages/Faq/List';
import AddFaq from '../../pages/Faq/Add';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Example} />
      <Route path="/zonas" exact component={ListZones} />
      <Route path="/usuarios" exact component={ListUsers} />
      <Route path="/usuarios/novo" component={AddUser} />
      <Route path="/veiculos" exact component={ListVehicles} />
      <Route path="/veiculos/novo" component={AddVehicle} />
      <Route path="/zonas/novo" component={AddZona} />
      <Route path="/recuperar-senha" component={RecoveryPassword} />
      <Route path="/faq" exact component={ListFaq} />
      <Route path="/faq/novo" component={AddFaq} />

      <RoutesAuthors />

      <Route path="/*" component={PaginaErro} />
    </Switch>
  );
}
