import { Button, Form, Input } from 'antd';
import { API_URL } from '../../../config/Api';

export default function AddFaq(props) {
  const onFinish = (values) => {
    fetch(`${API_URL}/faq`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: { 'Content-Type': 'application/json' },
    });

    alert('Tudo ok');

    props.history.push('/faq');
  };

  const onFinishFailed = (values) => {
    console.log('Finish failed:', values);
  };

  const validateMessages = {
    question: [{ required: true, message: 'Pergunta invalido' }],
    answer: [{ required: true, message: 'Resposta invalido' }],
  };

  return (
    <>
      <h1>Nova pergunta</h1>

      <Form
        name="basic"
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >

        <Form.Item
          label="Pergunta"
          name="question"
          rules={validateMessages.question}
        >
          <Input data-cy="question" />
        </Form.Item>

        <Form.Item
          label="Resposta"
          name="answer"
          rules={validateMessages.answer}
        >
          <Input data-cy="answer" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
          <Button data-cy="btn-ok" type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
