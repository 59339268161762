import { Link } from 'react-router-dom';

export default function PaginaErro() {
  return (
    <>
      <div>
        <h1>Página não encontrada</h1>
        <img src={`${process.env.PUBLIC_URL}/assets/images/pagina_erro.png`} width="500" height="300" />
        ;
      </div>
      <div>
        <Link to="/inicio">
          Voltar para o início
        </Link>
      </div>
    </>
  );
}
