import { Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { AppstoreOutlined, SettingOutlined, PieChartOutlined } from '@ant-design/icons';
import { useState } from 'react';

const { SubMenu } = Menu;

export default function Navbar() {
  const history = useHistory();
  const [state, setState] = useState({
    current: 'home',
  });

  const handleClick = (e) => {
    setState({
      current: e.key,
    });
  };

  return (
    <>
      <Menu
        theme="dark"
        onClick={handleClick}
        style={{ width: '100%' }}
        selectedKeys={[state.current]}
        mode="inline"
      >
        <Menu.Item key="home" onClick={() => history.push('/')} icon={<PieChartOutlined />}>
          PROJETO
        </Menu.Item>
        <SubMenu key="sub3" icon={<AppstoreOutlined />} title="Usuários">
          <Menu.Item key="list-users" onClick={() => history.push('/usuarios')}>
            Listar
          </Menu.Item>
          <Menu.Item key="add-user" onClick={() => history.push('/usuarios/novo')}>
            Novo
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub4" icon={<AppstoreOutlined />} data-cy="menu-vehicle" title="Veiculos">
          <Menu.Item key="list-vehicles" onClick={() => history.push('/veiculos')} data-cy="menu-vehicle-list">
            Listar
          </Menu.Item>
          <Menu.Item key="add-vehicle" onClick={() => history.push('/veiculos/novo')} data-cy="menu-vehicle-add">
            Novo
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub6" icon={<AppstoreOutlined />} data-cy="menu-zone" title="Zonas">
          <Menu.Item key="7" onClick={() => history.push('/zonas/novo')} data-cy="menu-zone-add">
            Novo
          </Menu.Item>
          <Menu.Item key="list-zones" onClick={() => history.push('/zonas')}>
            Listar
          </Menu.Item>
        </SubMenu>

        <SubMenu key="sub7" icon={<AppstoreOutlined />} data-cy="menu-faq" title="FAQ">
          <Menu.Item key="8" onClick={() => history.push('/faq/novo')} data-cy="menu-faq-add">
            Nova
          </Menu.Item>
          <Menu.Item key="list-faq" onClick={() => history.push('/faq')}>
            Listar
          </Menu.Item>
        </SubMenu>

        <SubMenu key="sub5" icon={<SettingOutlined />} title="Configuração">
          <Menu.Item key="logout" onClick={() => history.push('/login')}>
            Sair
          </Menu.Item>
        </SubMenu>
      </Menu>
    </>
  );
}
