import {
  Table, Space, Button, Modal, notification,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API_URL } from '../../../config/Api';

export default function ListUsers() {
  document.title = 'Usuários';

  const [users, setUsers] = useState([]);

  const removeUser = (user) => {
    Modal.confirm({
      title: 'Excluir Usuário',
      icon: <ExclamationCircleOutlined />,
      content: 'Voce tem certeza?',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: () => {
        fetch(`${API_URL}/users/${user.id}`, { method: 'DELETE' })
          .then(() => {
            notification.success({
              message: `Usuario ${user.name} removido com sucesso`,
            });

            // dessa forma não precisar buscar novamente
            setUsers(
              users.filter((values) => values.id !== user.id),
            );

            // busca de novo do banco de dados
            // findUsers();
          });
      },
    });
  };

  const findUsers = () => {
    const url = `${API_URL}/users/`;

    fetch(url)
      .then((response) => response.json())
      .then((users) => {
        setUsers(users);
      })
      .catch((error) => {
        alert('Não foi possível ler o banco de dados!');
      });
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Data de cadastro',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Último login',
      dataIndex: 'last_login',
      key: 'last_login',
    },

    {
      title: 'Ação',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button>Editar</Button>
          <Button onClick={() => removeUser(record)}>Excluir</Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    findUsers();
  }, []);

  return (
    <>
      <h1>Usuários</h1>
      <Table columns={columns} dataSource={users} />
    </>
  );
}
