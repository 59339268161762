import { Table, Space, Button } from 'antd';
import { useEffect, useState } from 'react';
import { API_URL } from '../../../config/Api';

export default function ListZones() {
  document.title = 'Zonas';

  const [zones, setZones] = useState([]);

  const columns = [
    {
      title: 'Sede',
      dataIndex: 'head_office',
      key: 'head_office',
    },
    {
      title: 'Número',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Juiz',
      dataIndex: 'judge',
      key: 'judge',
    },
    {
      title: 'Chefe',
      dataIndex: 'clerk',
      key: 'clerk',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'emaila',
    },
    {
      title: 'Ação',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button>Editar</Button>
          <Button>Excluir</Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const url = `${API_URL}/zones/`;
    fetch(url)
      .then((response) => response.json())
      .then((zones) => {
        setZones(zones);
      })
      .catch((error) => {
        alert('Não foi possível ler o banco de dados!');
      });
  }, []);

  return (
    <>
      <h1>Zonas</h1>
      <Table columns={columns} dataSource={zones} />
    </>
  );
}
